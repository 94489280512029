import styled from 'styled-components'
import ArrowLeftIcon from 'inter-frontend-svgs/lib/orangeds/XL/arrowleft'

import { Text } from '@/components/Text'

import * as Types from './types'

const iconSize = { width: 24, height: 24 }
export const ArrowLeft = styled(ArrowLeftIcon).attrs(iconSize)`
  path {
    stroke: var(--highlight-color);
  }
`

export const ContentPadding = styled.div<Pick<Types.AppBarProps, 'float'>>`
  padding-top: ${({ float = true }) => (float ? 'calc(var(--app-bar-height) - 2px)' : '0')};
`

export const Container = styled.div<Pick<Types.AppBarProps, 'float'>>`
  width: var(--app-bar-width);
  min-height: var(--app-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: ${({ float = true }) => (float ? 'fixed' : 'inherit')};
  background: var(--app-bar-background-color);
  padding: var(--app-bar-container-padding);
  top: 0;
  left: 0;
  z-index: var(--app-bar-z-index);
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 5px;

  & > span {
    word-break: break-all;
  }
`

export const Title = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h3',
  semiBold: true,
})`
  align-self: center;
  margin: auto;
`

export const CleanIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: transparent;
`
